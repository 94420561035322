import React from "react";
import cx from "classnames";
import { TypeAttributes } from "rsuite/esm/@types/common";
export interface LoaderProps {
  readonly className?: string;

  /** Centered in the container */
  readonly center?: boolean;

  /** Whether the background is displayed */
  readonly backdrop?: boolean;

  /** An alternative dark visual style for the Loader */
  readonly inverse?: boolean;

  /** The icon is displayed vertically with the text */
  readonly vertical?: boolean;

  /** Custom descriptive text */
  readonly content?: React.ReactNode;

  /** The speed at which the loader rotates */
  readonly speed?: "normal" | "fast" | "slow";

  /** A loader can have different sizes */
  readonly size?: TypeAttributes.Size;
}

/**
 * The `Loader` component is used to indicate the loading state of a page or a section.
 * @see https://rsuitejs.com/components/loader
 */
export default function NovelLoader(props: LoaderProps): JSX.Element {
  const {
    className,
    inverse,
    backdrop,
    speed = "normal",
    center,
    vertical,
    content,
    size,
    ...rest
  } = props;
  const hasContent = !!content;
  return <div role="progressbar" {...rest} className={cx(className, "novel-loader-wrapper", `novel-loader-speed-${speed}`, size, {
    "novel-loader-backdrop-wrapper": backdrop,
    "novel-loader-has-content": hasContent,
    vertical,
    inverse,
    center
  })}>
            {backdrop && <div className="novel-loader-backdrop" />}
            <div className="novel-loader">
                <span className="novel-loader-spin" />
                {hasContent && <span className="novel-loader-content">{content}</span>}
            </div>
        </div>;
}
NovelLoader.displayName = "Loader";