import React from "react";
import { InstallApplePassCmp } from "@customer-pass/components/InstallPassCmp/InstallApplePassCmp";
import { InstallAndroidPassCmp } from "@customer-pass/components/InstallPassCmp/InstallAndroidPassCmp";
import { PassInstallFlow } from "@novel/shared/utils/getInstallPassFlow";
export function InstallPassCmp({
  walletPassInstallFlow
}: {
  readonly walletPassInstallFlow?: PassInstallFlow;
}): JSX.Element {
  return walletPassInstallFlow === "ios-phone" ? <InstallApplePassCmp /> : <InstallAndroidPassCmp />;
}