import { useAuthLink } from "@customer-pass/redux/actionCreators/auth/authLink";
import { loadCurrentWalletPass } from "@customer-pass/redux/actionCreators/session";
import { useCurrencyCode, useRewardsTier, useUserDisplayName } from "@customer-pass/redux/reducers/auth";
import { useGuessedPhoneOperatingSystem } from "@customer-pass/redux/reducers/walletPassInstallFlow";
import { useNovelCustomerPassDispatch, useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { PassPreview } from "@novel/shared/components/PassPreview";
import { downloadFileUrlWithClick } from "@novel/shared/utils/downloadBlob";
import { PassInstallFlow } from "@novel/shared/utils/getInstallPassFlow";
import { poll } from "@novel/shared/utils/poll";
import { infoToast } from "@novel/shared/utils/toastUtils";
import { useAsyncCallback } from "@novel/shared/hooks/useAsyncCallback";
import { checkIsSafari } from "@novel/shared/utils/userAgentUtils";
import React, { useLayoutEffect, useMemo } from "react";
import { defaultEndpoint, defaultScriptUrlPattern, FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
export function PassPreviewCmp({
  walletPassInstallFlow,
  isAuthRedirect
}: {
  readonly walletPassInstallFlow?: PassInstallFlow;
  readonly isAuthRedirect?: boolean;
}): JSX.Element | null {
  const userDisplayName = useUserDisplayName();
  const user = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.rewardsStatus);
  const novelUser = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.novelUser);
  const guessedPhoneOperatingSystem = useGuessedPhoneOperatingSystem();
  const dispatch = useNovelCustomerPassDispatch();
  const isAppleDevice = useNovelCustomerPassSelector(state => state.passUi.isAppleDevice);
  const computedCountryCode = useNovelCustomerPassSelector(state => state.passUi.computedCountryCode);
  const orgId = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.org.id);
  const orgName = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.org.orgName);
  const currentOrgHandle = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.currentOrgHandle);
  const referredBy = useNovelCustomerPassSelector(state => state.passUi.resolvedReferrer);
  const rewardsConfig = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.rewardsConfig);
  const rewardsStatusId = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.rewardsStatus?.id);
  const authLink = useAuthLink();
  const rewardsTier = useRewardsTier();
  const finalizedRewardsTier = useMemo(() => {
    if (rewardsTier) {
      return {
        ...rewardsTier,
        orgName,
        userDisplayName,
        // we show a pseudo qr code on non-mobile because it would be confusing
        // for the QR code flow two have two QR codes on the screen
        showPseudoQr: walletPassInstallFlow !== "android-phone" && walletPassInstallFlow !== "ios-phone"
        // for demo passes it feels more branded
        // to have the site title here
        // welcomeMessage: isDemoPass && siteTitle ? siteTitle : rewardsTier.welcomeMessage,
      };
    }
  }, [rewardsTier, orgName, userDisplayName, walletPassInstallFlow]);

  // If computed currency not enabled, use main shop currency
  const currencyCode = useCurrencyCode();
  const brandData = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.brand);
  const resolvedCustomer = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer);
  const currentWalletPass = useMemo(() => resolvedCustomer?.currentWalletPass, [resolvedCustomer?.currentWalletPass]);
  const passAlreadyInstalled = useMemo(() => !!currentWalletPass, [currentWalletPass]);
  const passWasAlreadyInstalled = useMemo(() => resolvedCustomer && !!currentWalletPass,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [!!resolvedCustomer]);

  // cannot use blob here as inital "welcome" state will mean this will be an "update", need to re-fetch
  const passInstallLink = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.passInstallLink);
  const passDownloadUrl = useNovelCustomerPassSelector(state => state.passUi.passDownloadUrl);
  const passDownloadUrlLoading = useNovelCustomerPassSelector(state => state.passUi.passDownloadUrlLoading);
  const openPass = useAsyncCallback(async () => {
    if (passInstallLink) {
      if (isAppleDevice) {
        if (orgId && rewardsStatusId) {
          if (!currentWalletPass) {
            // smoother experience, only works in Safari
            if (passDownloadUrl && checkIsSafari()) {
              downloadFileUrlWithClick({
                fileName: `${orgId}-${rewardsStatusId}.pkpass`,
                passBlobUrl: passDownloadUrl
              });
              return;
            } else {
              window.open(passInstallLink, "_system");
              return;
            }
          }
          // window.open("wallet://", "_blank");
          //ref https://stackoverflow.com/questions/45105273/how-to-create-a-deep-link-to-the-apple-wallet-app-from-my-website
          window.open("shoebox://", "_blank");

          // if user navigates to the wallet, can safely close this window
          setTimeout(() => {
            window.close();
          }, 5000);
        }
      } else {
        window.open(passInstallLink, "_system");

        // if user already had the pass installed on android, this navigates
        // them to the pass so can safely close this window
        if (passWasAlreadyInstalled) {
          setTimeout(() => {
            window.close();
          }, 5000);
        }
      }
    }
  }, [orgId, rewardsStatusId, passInstallLink, isAppleDevice, passDownloadUrl, currentWalletPass, passWasAlreadyInstalled]);

  // poll for pass install having happened and redirect to the wallet
  useLayoutEffect(() => {
    if (isAuthRedirect || passWasAlreadyInstalled) {
      return;
    }
    const isMobileFlow = walletPassInstallFlow === "ios-phone" || walletPassInstallFlow === "android-phone";
    if (isMobileFlow && resolvedCustomer && !currentWalletPass) {
      return poll({
        pollFunction: async () => {
          const res = await dispatch(loadCurrentWalletPass());
          return res.type !== "ERROR_LOADING_CURRENT_WALLET_PASS" && !!res.payload?.currentWalletPass;
        },
        emitResponse: (passIsInstalled, isExiting) => {
          if (passIsInstalled && !isExiting) {
            if (walletPassInstallFlow === "ios-phone") {
              infoToast({
                content: 'Your pass has been installed! \nClick "Check out your pass" below to view in your wallet!',
                placement: "top-center"
              });
            } else if (walletPassInstallFlow === "android-phone") {
              // for android, if the user adds the pass they will automatically
              // be taken to their wallet so we can safely close this tab
              setTimeout(() => {
                window.close();
              }, 5000);
            }
          }
        },
        exitCondition: passIsInstalled => passIsInstalled
      });
    }
  }, [passWasAlreadyInstalled, isAuthRedirect, dispatch, resolvedCustomer, currentWalletPass, walletPassInstallFlow]);
  useLayoutEffect(() => {
    if (!isAuthRedirect && passWasAlreadyInstalled) {
      infoToast({
        content: 'You already installed your pass! \nClick "Check out your pass" below to view in your wallet!',
        placement: "top-center"
      });
    }
  }, [isAuthRedirect, passWasAlreadyInstalled]);

  // auto-install pass if user is on iOS and has not already installed it
  // can't do for Android as it requires a user click in that OS
  useLayoutEffect(() => {
    if (!isAuthRedirect && !passWasAlreadyInstalled && !currentWalletPass && passDownloadUrl && walletPassInstallFlow === "ios-phone") {
      downloadFileUrlWithClick({
        fileName: `${orgId}-${rewardsStatusId}.pkpass`,
        passBlobUrl: passDownloadUrl
      });
    }
  }, [isAuthRedirect, openPass, passDownloadUrl, passWasAlreadyInstalled, currentWalletPass, walletPassInstallFlow, orgId, rewardsStatusId]);
  return !walletPassInstallFlow || !finalizedRewardsTier ? null : <FpjsProvider loadOptions={{
    apiKey: "bRWsfMpLgEu4jGFP0aq1",
    endpoint: ["https://novel.com/YfMjRv52UKYTrn9E/Ki08S5EqgYSjopzU", defaultEndpoint],
    scriptUrlPattern: ["https://novel.com/YfMjRv52UKYTrn9E/sngDJl1mijcAxd6V?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>", defaultScriptUrlPattern]
  }}>
            <PassPreview brandLogo={brandData?.logoUrl} userId={user?.id} passOrgNameOverride={rewardsConfig?.passOrgNameOverride} walletPassInstallFlow={walletPassInstallFlow} isAppleDevice={!!isAppleDevice} computedCountryCode={computedCountryCode} computedCurrencyCode={currencyCode} novelUser={novelUser} referredBy={referredBy} passPreviewFields={finalizedRewardsTier} openPass={openPass} passIsLoading={passDownloadUrlLoading} authLink={authLink} passAlreadyInstalled={passAlreadyInstalled} guessedPhoneOperatingSystem={guessedPhoneOperatingSystem} currentOrgHandle={currentOrgHandle} />
        </FpjsProvider>;
}