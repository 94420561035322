import {
    PASSWORDLESS_SIX_DIGIT_CODE_EXPIRATION_TIMEOUT,
    customerPassPrefix,
} from "@novel/shared/utils/appConstants";
import { typedFrontendPassApi } from "@customer-pass/utils/typedFrontendPassApi";
import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { NovelCustomerPassThunkAction } from "@customer-pass/redux/customerPassTypedRedux";
import { asyncDispatch } from "@customer-pass/redux/utils/asyncDispatch";
import { TypedCustomerApi } from "@novel/shared/typedCustomerApi/TypedCustomerRoutes";
import { stringify, ParsedQs } from "qs";

let currentTimeout: number;
export const populateAuthLink =
    (
        customerApi: TypedCustomerApi = typedFrontendPassApi,
        query?: ParsedQs,
    ): NovelCustomerPassThunkAction<
        "LOADING_AUTH_LINK" | "ERROR_LOADING_AUTH_LINK" | "LOADED_AUTH_LINK"
    > =>
    async (dispatch) => {
        if (typeof window !== "undefined") {
            clearTimeout(currentTimeout);
        }

        const currentQueryString = query
            ? stringify(query, { addQueryPrefix: true })
            : typeof window !== "undefined"
              ? window.location.search
              : "";
        const baseUrl = `${
            customerApi.prefix || `${window.location.protocol}//${window.location.host}`
        }${customerPassPrefix}`;
        const redirectUrl = `${baseUrl}${currentQueryString}`;

        return asyncDispatch(
            customerApi.postReq("/api/customer/auth/passwordless/pass-auth-link", {
                reqBody: {
                    redirectUrl,
                },
            }),
            () =>
                dispatch({
                    type: "LOADING_AUTH_LINK",
                    payload: undefined,
                }),
            (failure) => {
                return dispatch({
                    type: "ERROR_LOADING_AUTH_LINK",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                });
            },
            (result) => {
                // refresh the link 30 seconds before it times out
                if (typeof window !== "undefined") {
                    currentTimeout = setTimeout(() => {
                        dispatch(populateAuthLink());
                    }, PASSWORDLESS_SIX_DIGIT_CODE_EXPIRATION_TIMEOUT - 30000) as unknown as number;
                }

                return dispatch({
                    type: "LOADED_AUTH_LINK",
                    payload: {
                        authLink: result.authLink,
                    },
                });
            },
        );
    };

export function useAuthLink(): string | null {
    return useNovelCustomerPassSelector((state) => state.auth.authLink);
}
