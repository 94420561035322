import { SurfaceableError } from "@novel/shared/utils/SurfaceableError";

export async function getBlobUrl({
    url,
    errorMessage = "Failed to download, please try again in a few minutes.",
}: {
    url: string;
    fileName: string;
    errorMessage?: string;
}): Promise<string> {
    const response = await fetch(url);
    if (!response.ok) {
        throw new SurfaceableError(errorMessage);
    }
    const data = await response.blob();
    const passBlobUrl = URL.createObjectURL(data);
    return passBlobUrl;
}

export function downloadFileUrlWithClick({
    fileName,
    passBlobUrl,
}: {
    fileName: string;
    passBlobUrl: string;
}): void {
    const element = document.createElement("a");
    element.setAttribute("target", "_system");
    element.setAttribute("href", passBlobUrl);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
