import { Box } from "@novel/shared/components/Box";
import React, { useMemo } from "react";
import { createGlobalStyle } from "styled-components";
import Image from "next/legacy/image";
import HandWaveIcon from "@novel/shared/icons/HandWaveIconHighRes.png";
import { CopyToClipBoardCmp } from "@novel/shared/components/CopyToClipBoardCmp";

// styling with this is necessary for SSR to work
const PreApprovalAndroidStyle = createGlobalStyle`
    .rs-modal-body {
        overflow: hidden !important;
    }
    .small-logo-container {
        height: 121px;
        width: 121px;
    }
    #pass-modal-container, .Toastify{
        z-index: 9999;
    }
    .form-submit-area {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .already-account {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: inline-block;
    }

    .edit-text-light {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #2B68EC;
        cursor: pointer;
        background: transparent;
    }
    .account-extra {
        margin-top: 10px;
        text-align: center;
    }
    .small-logo-container {
        margin-left: auto;
        margin-right: auto;
    }
    .box-login {
        padding-left: 10px;
        padding-right: 10px;
        background: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 999;
    }
    .hello-text {
        font-weight: 600;
        font-size: 40px;
        line-height: 38px;
        text-align: center;
        color: black;
        text-align: center;
        font-family: "Poppins", sans-serif;
    }
    .hello-subtext {
        font-size: 25px;
        color: #000000;
        text-align: center;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }
    .auth-text {
        font-size: 28px;
        color: black;
        font-weight: 600;
        text-align: center;
        font-family: "Poppins", sans-serif;
    }
    .align-ol{
        margin: 0px;
        padding: 0px;
    }
    .align-ol li {
        display: list-item;
        text-align: start;
        margin-bottom: 10px;
    }
    .auth-subtext {
        font-size: 13px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        line-height: 1.5;
    }
    .login-auth-auth-state {
        margin: 4rem 0;
        text-align: center;
    }
    .login-auth-auth-state > button {
        width: 50%;
    }

    .imagelock{
        vertical-align: middle;
        max-width: 100%;
        display: inline;
    }

    .code-message {
        margin: 1rem 0;
    }
    .resend-code {
        font-weight: bold;
        display: contents;
        color: black;
        cursor: pointer;
    }
    .back-to-login {
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 1px;
        color: rgb(0, 0, 0);
    }
    .hello-subtext-mini {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        margin-top: 15px; 
        color: #A5A5A7;
    }
    .text-for-small-lookups {
        justify-content: center;
        align-items: center;
    }
    .button-big-blue {
        width: 139px;
        background: #2B68EC;
        border-radius: 3px;
        color: white;
        font-weight: 500;
        font-size: 18px;
        padding: 7px 30px;
        height: 55px;
        line-height: 20px;
    }
    .form-box {
        width: 100%;
        row-gap: 3.5rem !important;
    }

    @media (max-height: 570px){
        .form-box {
            width: 100%;
            row-gap: 1.5rem !important;
        }
    }

    @media (min-width: 970px) {
        .auth-code-container {
            width: 80%;
        }
        .form-submit-area {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        .account-extra {
            display: inline-block;
            margin-left: 20px;
        }
        .hello-subtext-mini {
            text-align: inherit;
        }
        .small-logo-container {
            display: none !important;
        }
        .auth-text {
            text-align: left;
        }
        .auth-subtext {
            text-align: left;
        }
        .text-for-small-lookups {
            justify-content: flex-start;
            align-items: flex-start;
        }

        .box-login {
            padding-left: 10px;
            padding-top: 73px;
            padding-right: 10px;
        }
        .form-box {
            width: 400px;
            row-gap: 5.5rem;
        }
        .back-to-login {
            font-size: 12px;
            text-decoration: underline;
            text-underline-offset: 3px;
            text-decoration-thickness: 1px;
            color: rgb(0, 0, 0);
        }
        .box-content-login-prompt {
            align-items: flex-start;
        }
    }
`;
interface IUnsupportedBrowserScreenProps {
  readonly authLink: string | null;
}
export const UnsupportedBrowserScreen = ({
  authLink
}: IUnsupportedBrowserScreenProps): JSX.Element | null => {
  return <React.Fragment>
            <Box className="box-login">
                <Box column rowGap={5.5} className="form-box" style={useMemo(() => ({
        padding: "20px"
      }), [])}>
                    <Box className="small-logo-container">
                        <Image src={HandWaveIcon} alt="Logo" layout="fill" priority />
                    </Box>
                    <React.Fragment>
                        <Box column rowGap={1} className="box-content-login-prompt" justifyContent="center" alignItems={"center"}>
                            <Box className="hello-text">You're almost there!</Box>
                            <Box className="hello-subtext-mini">
                                Please copy this link and open it in Chrome or Safari to get your
                                pass!
                            </Box>
                        </Box>
                        <Box column>
                            <CopyToClipBoardCmp stringToCopy={authLink || ""} />
                        </Box>
                        <Box className="box-content-login-prompt" justifyContent="center" alignItems={"center"}>
                            <Box className="hello-subtext-mini">
                                (Note: If you’re still seeing this screen, turn off any 3rd party
                                ad-blockers and refresh.)
                            </Box>
                        </Box>
                    </React.Fragment>
                </Box>
            </Box>
            <PreApprovalAndroidStyle />
        </React.Fragment>;
};