import React, { useMemo, useCallback } from "react";
import { useWalletPassInstallFlow } from "@customer-pass/redux/reducers/walletPassInstallFlow";
import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { IOrgHandle } from "@novel/shared/interfaces/OrgHandle";
import { vendorPortalPrefix } from "@novel/shared/utils/appConstants";
import { openInNewTab } from "@novel/shared/utils/locationUtils";
import { appPrefixedHostWithProtocol } from "@novel/shared/utils/appDomainConstants";
export function DemoInstallButton(): JSX.Element | null {
  const walletPassInstallFlow = useWalletPassInstallFlow();
  const resolvedOrg = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg);
  const resolvedCustomer = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer);
  const myShopifyHandles = useMemo(() => resolvedOrg?.myShopifyHandles?.filter(handle => handle.baseHost) || [], [resolvedOrg]);
  const openInstallLink = useCallback((handle: IOrgHandle) => {
    if (handle?.baseHost) {
      // screwed up computing baseHost for a while - fixed now, but this is a fallback
      const correctedBaseHost = handle.baseHost.includes("myshopify") ? handle.baseHost : `${handle.baseHost}.myshopify.com`;
      openInNewTab(`${appPrefixedHostWithProtocol}${vendorPortalPrefix}?shop=${correctedBaseHost}`);
    }
  }, []);
  const demoInstallButtonClick = useCallback(() => {
    openInstallLink(myShopifyHandles[0]);
  }, [myShopifyHandles, openInstallLink]);

  // only show on demo shops on desktop after they've logged in
  if (walletPassInstallFlow !== "large-device" || !resolvedCustomer || !resolvedOrg?.org || resolvedOrg?.shopData || !myShopifyHandles.length) {
    return null;
  }
  return <React.Fragment>
            <div className="demo-install-container">
                <div className="demo-install-description">
                    <span style={{
          marginBottom: 1
        }}>
                        This demo pass is made from your site's assets!
                    </span>
                    <span>Ready to make it yours?</span>
                </div>
                <div className="demo-install-button-wrapper">
                    <button className="button-big demo-install-button" onClick={demoInstallButtonClick}>
                        Start Your 30-day Trial
                    </button>
                </div>
            </div>
            <style>{`
                .demo-install-container {
                    position: fixed;
                    display: inline-flex;
                    flex-direction: column;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1051;
                    bottom: 20px;
                }

                .demo-install-description {
                    display: flex;
                    flex-direction: column;
                    min-width: 470px;
                    margin-bottom: 8px;

                    text-align: center;
                    color: #fff;
                }

                .demo-install-button-wrapper {
                    margin: auto;
                }

                .demo-install-button {
                    box-shadow: 0 0 10px -1px #fff !important;
                }

                @media (max-width: 768px) {
                    .demo-install-container {
                        display: none;
                        bottom: 6px;
                    }
                }

                @media (max-height: 810px) {
                    .demo-install-description {
                        display: none;
                    }
                }
            `}</style>
        </React.Fragment>;
}