import { Box } from "@novel/shared/components/Box";
import React, { useCallback, useMemo, useState } from "react";
import HandWaveIcon from "@novel/shared/icons/HandWaveIconHighRes.png";
import { createGlobalStyle } from "styled-components";
import Image from "next/legacy/image";
import { infoToast } from "@novel/shared/utils/toastUtils";
import { useRouter } from "next/router";
import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";

// styling with this is necessary for SSR to work
const PreApprovalAndroidStyle = createGlobalStyle`
    .rs-modal-body {
        overflow: hidden !important;
    }
    .small-logo-container {
        height: 121px;
        width: 121px;
    }
    #pass-modal-container, .Toastify{
        z-index: 9999;
    }
    .form-submit-area {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .already-account {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: inline-block;
    }

    .edit-text-light {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #2B68EC;
        cursor: pointer;
        background: transparent;
    }
    .account-extra {
        margin-top: 10px;
        text-align: center;
    }
    .small-logo-container {
        margin-left: auto;
        margin-right: auto;
    }
    .box-login {
        padding-left: 10px;
        padding-right: 10px;
        background: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 999999;
    }
    .hello-text {
        font-weight: 600;
        font-size: 40px;
        line-height: 38px;
        text-align: center;
        color: black;
        text-align: center;
        font-family: "Poppins", sans-serif;
    }
    .hello-subtext {
        font-size: 25px;
        color: #000000;
        text-align: center;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }
    .auth-text {
        font-size: 28px;
        color: black;
        font-weight: 600;
        text-align: center;
        font-family: "Poppins", sans-serif;
    }
    .align-ol{
        margin: 0px;
        padding: 0px;
    }
    .align-ol li {
        display: list-item;
        text-align: start;
        margin-bottom: 10px;
    }
    .auth-subtext {
        font-size: 13px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        line-height: 1.5;
    }
    .login-auth-auth-state {
        margin: 4rem 0;
        text-align: center;
    }
    .login-auth-auth-state > button {
        width: 50%;
    }

    .imagelock{
        vertical-align: middle;
        max-width: 100%;
        display: inline;
    }

    .code-message {
        margin: 1rem 0;
    }
    .resend-code {
        font-weight: bold;
        display: contents;
        color: black;
        cursor: pointer;
    }
    .back-to-login {
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 1px;
        color: rgb(0, 0, 0);
    }
    .hello-subtext-mini {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        margin-top: 15px; 
        color: #A5A5A7;
    }
    .text-for-small-lookups {
        justify-content: center;
        align-items: center;
    }

    .button-big-blue {
        width: 139px;
        background: #2B68EC;
        border-radius: 3px;
        color: white;
        font-weight: 500;
        font-size: 18px;
        padding: 7px 30px;
        height: 55px;
        line-height: 20px;
    }
    .form-box {
        width: 100%;
        row-gap: 3.5rem !important;
    }

    @media (max-height: 570px){
        .form-box {
            width: 100%;
            row-gap: 1.5rem !important;
        }
    }

    @media (min-width: 970px) {
        .auth-code-container {
            width: 80%;
        }
        .form-submit-area {
            display: flex;
            align-items: center;
            flex-direction: row;
            min-height: 2.2rem;
        }
        .account-extra {
            display: inline-block;
            margin-left: 20px;
        }
        .hello-subtext-mini {
            text-align: inherit;
        }
        .small-logo-container {
            display: none !important;
        }
        .auth-text {
            text-align: left;
        }
        .auth-subtext {
            text-align: left;
        }
        .text-for-small-lookups {
            justify-content: flex-start;
            align-items: flex-start;
        }

        .box-login {
            padding-left: 10px;
            padding-top: 73px;
            padding-right: 10px;
        }
        .form-box {
            width: 400px;
            row-gap: 5.5rem;
        }
        .back-to-login {
            font-size: 12px;
            text-decoration: underline;
            text-underline-offset: 3px;
            text-decoration-thickness: 1px;
            color: rgb(0, 0, 0);
        }
        .box-content-login-prompt {
            align-items: flex-start;
        }
    }
`;
interface IPreApprovalAndroidProps {
  readonly enableWebPushNotifications: () => Promise<void>;
  readonly loading?: boolean;
  readonly requiresSettingsChange?: boolean;
}
export const PreApprovalAndroid = ({
  enableWebPushNotifications,
  loading,
  requiresSettingsChange
}: IPreApprovalAndroidProps): JSX.Element | null => {
  const [tempHide, setTempHide] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const customerOrgHandle = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.customerOrgHandle);
  const router = useRouter();
  const allowNoThanks = useCallback(() => {
    setTempHide(true);
    setTimeout(() => {
      infoToast({
        content: 'This pass includes notifications, click "Approve" to continue'
      });
    }, 300);
    setTimeout(() => {
      setTempHide(false);
    }, 1000);
  }, []);
  const formBoxStyleMemo = useMemo(() => ({
    padding: "20px"
  }), []);
  return tempHide ? null : <React.Fragment>
            <Box className="box-login">
                <Box column rowGap={5.5} className="form-box" style={formBoxStyleMemo}>
                    <Box className="small-logo-container">
                        <Image src={HandWaveIcon} alt="Logo" layout="fill" objectFit="contain" priority />
                    </Box>
                    <React.Fragment>
                        <Box column rowGap={1} className="box-content-login-prompt" justifyContent="center" alignItems={"center"}>
                            <Box className="hello-text">You're almost there!</Box>
                            <Box className="hello-subtext-mini">
                                {requiresSettingsChange ? <span>
                                        <p style={{
                  marginBottom: "15px"
                }}>
                                            Notifications are currently disabled, Please Enable
                                            notifications with the instructions below to install
                                            your pass!
                                        </p>
                                        <ol className="align-ol">
                                            <li>
                                                <p style={{
                      display: "inline",
                      marginRight: "5px"
                    }}>
                                                    To the right of the address bar, tap More
                                                </p>
                                                <img className="imagelock" src="//lh3.googleusercontent.com/oLoRPrHJd7m46sWijX6zBWnEnfslP62AxJSwt5Nj0bNbpaYHz2pyscExleiofsH2kQ=h36" width="18" height="18" alt="Lock" data-mime-type="image/png" data-alt-src="//lh3.googleusercontent.com/KUCFEC5rWlZYX3_jKFm7JhCr3LhjvSCbnlnbz8lfFsqT9jyEzwiWF7FLKAgkrzUhdjg" />
                                                {/* <img
                                                    className="imagelock"
                                                    src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36"
                                                    width="18"
                                                    height="18"
                                                    alt="and then"
                                                    data-mime-type="image/png"
                                                    data-alt-src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw"
                                                 />
                                                 <strong> Settings</strong> */}
                                            </li>
                                            <li>
                                                <p style={{
                      display: "inline",
                      marginRight: "5px"
                    }}>
                                                    Tap
                                                    <strong> Settings</strong>
                                                    <img className="imagelock" src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" width="18" height="18" alt="and then" data-mime-type="image/png" data-alt-src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw" />
                                                    <strong> Notifications</strong>
                                                    <img className="imagelock" src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" width="18" height="18" alt="and then" data-mime-type="image/png" data-alt-src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw" />
                                                    <strong> Notifications Categories</strong>
                                                </p>
                                            </li>
                                            <li>
                                                <p style={{
                      display: "inline",
                      marginRight: "5px"
                    }}>
                                                    Find{" "}
                                                    {window ? `${window.location.hostname}` : `{url} (e.g. lashify.novel.com)`}{" "}
                                                    and turn the toggle on
                                                </p>
                                            </li>
                                            {/* <li>
                                                <p
                                                    style={{
                                                        display: "inline",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    At the top, turn the slider On
                                                </p>
                                             </li> */}
                                            <li>
                                                <p style={{
                      display: "inline",
                      marginRight: "5px"
                    }}>
                                                    Then{" "}
                                                    <strong onClick={() => router.reload()}>
                                                        Click here to Reload
                                                    </strong>
                                                </p>
                                            </li>
                                        </ol>
                                    </span> : "Your wallet pass includes notifications for exclusive offers, click Enable to continue"}
                            </Box>
                        </Box>
                        <Box column>
                            <div className="form-submit-area">
                                {requiresSettingsChange ? <div className="form-submit-area">
                                        <button type="button" className="edit-text-light" onClick={() => {
                  if (!isReloading) {
                    setIsReloading(true);
                    router.reload();
                  }
                }} disabled={isReloading}>
                                            {isReloading ? "Reloading..." : "Once you have completed the above, click to reload this page"}
                                        </button>
                                    </div> : <React.Fragment>
                                        <button type="button" className="edit-text-light" onClick={allowNoThanks} disabled={loading}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="button-big-blue" onClick={enableWebPushNotifications} disabled={loading}>
                                            {loading ? "Loading..." : "Enable"}
                                        </button>
                                    </React.Fragment>}
                            </div>
                        </Box>
                    </React.Fragment>
                </Box>
            </Box>
            <PreApprovalAndroidStyle />
        </React.Fragment>;
};