import React, { useEffect } from "react";
import { IPassPreviewWithInstallFlow } from "@novel/shared/components/PassPreview/types";
import { DesktopPassPreview } from "@novel/shared/components/PassPreview/DesktopPassPreview";
import { AppleInstallPassPreview } from "@novel/shared/components/PassPreview/AppleInstallPassPreview";
import { AndroidInstallPassPreview } from "@novel/shared/components/PassPreview/AndroidInstallPassPreview";
import { FallbackFlow } from "@novel/shared/components/PassPreview/FallbackFlow";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { typedFrontendPassApi } from "@novel/shared/typedCustomerApi/typedFrontendPassApi";
import { useAsyncCallback } from "@novel/shared/hooks/useAsyncCallback";
export function PassPreview({
  currentOrgHandle,
  brandLogo,
  walletPassInstallFlow,
  guessedPhoneOperatingSystem,
  computedCurrencyCode,
  passPreviewFields,
  openPass,
  novelUser,
  userId,
  referredBy,
  passIsLoading,
  authLink,
  passAlreadyInstalled,
  passOrgNameOverride
}: IPassPreviewWithInstallFlow): JSX.Element {
  const {
    isLoading,
    error,
    data,
    getData
  } = useVisitorData({
    extendedResult: true
  }, {
    immediate: true
  });
  const saveFingerPrintId = useAsyncCallback(async () => {
    if (data && !isLoading && novelUser) {
      await typedFrontendPassApi.postReq("/api/customer/fingerprint", {
        reqBody: {
          fingerprintId: data.visitorId,
          email: novelUser.primaryEmail,
          browser: data.browserName,
          ipAddress: data.ip
        }
      });
    }
  }, [data, isLoading, novelUser]);
  const shouldSaveFingerPrintId = (walletPassInstallFlow === "android-phone" || walletPassInstallFlow === "ios-phone") && data && !isLoading && novelUser;
  useEffect(() => {
    if (shouldSaveFingerPrintId) {
      saveFingerPrintId();
    }
  }, [shouldSaveFingerPrintId, saveFingerPrintId]);
  const googleLogoImageUrl = passPreviewFields.googleLogoImageUrl || brandLogo;
  const appleLogoImageUrl = passPreviewFields.appleLogoImageUrl || passPreviewFields.appleLogoImageUrlx2 || passPreviewFields.appleLogoImageUrlx3 || googleLogoImageUrl;
  if (walletPassInstallFlow === "android-phone" || walletPassInstallFlow === "ios-phone") {
    if (walletPassInstallFlow === "android-phone") {
      return <AndroidInstallPassPreview openPass={openPass} currencyCode={computedCurrencyCode} passIsLoading={passIsLoading} passAlreadyInstalled={passAlreadyInstalled} {...passPreviewFields} referredBy={referredBy} {...googleLogoImageUrl ? {
        googleLogoImageUrl
      } : {}} {...appleLogoImageUrl ? {
        appleLogoImageUrl
      } : {}} currentOrgHandle={currentOrgHandle} />;
    }
    // if it's not an iPhone we make some guesses as to which phone type they're likely
    // to own based on whether or not they are on an apple device + the country code
    else if (walletPassInstallFlow === "ios-phone") {
      return <AppleInstallPassPreview openPass={openPass} referredBy={referredBy} currencyCode={computedCurrencyCode} passIsLoading={passIsLoading} passAlreadyInstalled={passAlreadyInstalled} {...passPreviewFields} {...googleLogoImageUrl ? {
        googleLogoImageUrl
      } : {}} {...appleLogoImageUrl ? {
        appleLogoImageUrl
      } : {}} currentOrgHandle={currentOrgHandle} />;
    }
  } else if (walletPassInstallFlow === "large-device") {
    return <DesktopPassPreview passOrgNameOverride={passOrgNameOverride} guessedPhoneOperatingSystem={guessedPhoneOperatingSystem} currencyCode={computedCurrencyCode} userId={userId} {...passPreviewFields} {...googleLogoImageUrl ? {
      googleLogoImageUrl
    } : {}} {...appleLogoImageUrl ? {
      appleLogoImageUrl
    } : {}} authLink={authLink} />;
  }
  return <FallbackFlow userId={userId} walletPassInstallFlow={walletPassInstallFlow} authLink={authLink} />;
}