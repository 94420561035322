import React, { useMemo, Suspense, lazy } from "react";
import { CustomerPassTypedRoute } from "@customer-pass/definitions/routeDefinitions";
import { FixedPxBox } from "@novel/shared/components/Box";
import { useWalletPassInstallFlow } from "@customer-pass/redux/reducers/walletPassInstallFlow";
import { PassPreviewCmp } from "@customer-pass/components/PassPreviewCmp";
import { InstallPassCmp } from "@customer-pass/components/InstallPassCmp";
import { useIsDemoOrg } from "@customer-pass/redux/reducers/organization";
import { NOVEL_PASS_LINK_TYPE_PARAM } from "@novel/shared/utils/appStorefrontConstants";
import { DemoInstallButton } from "@customer-pass/components/DemoInstallButton";
const DemoInstallButtonCmpLazy = lazy(() => import("@customer-pass/components/DemoInstallButton").then(module => ({
  default: module.DemoInstallButton
})));
export const PassPageCmp: CustomerPassTypedRoute<"/"> = function PassPageCmp({
  props
}) {
  const walletPassInstallFlow = useWalletPassInstallFlow();
  const isPhone = walletPassInstallFlow === "ios-phone" || walletPassInstallFlow === "android-phone";
  const isShowingModal = walletPassInstallFlow === "large-device";
  const isDemoShop = useIsDemoOrg();
  return <React.Fragment>
            <FixedPxBox height="100%" width="100%" alignItems="center" justifyContent={isPhone || walletPassInstallFlow === "wearable" ? "center" : "space-between"} column style={useMemo(() => ({
      backgroundColor: isPhone || walletPassInstallFlow === "wearable" ? "white" : "inherit"
    }), [isPhone, walletPassInstallFlow])}>
                {isPhone && <InstallPassCmp walletPassInstallFlow={walletPassInstallFlow} />}
                <FixedPxBox className="pass-preview-cmp-container" maxHeight={isShowingModal ? "45%" : "100%"} height="100%" column width="100%" alignItems="center" justifyContent="center" style={useMemo(() => isShowingModal && !isDemoShop ? {
        transform: "translateY(40px)"
      } : undefined, [isShowingModal, isDemoShop])}>
                    <PassPreviewCmp walletPassInstallFlow={walletPassInstallFlow} isAuthRedirect={!!props[NOVEL_PASS_LINK_TYPE_PARAM]} />
                </FixedPxBox>
            </FixedPxBox>
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <Suspense fallback={<React.Fragment />}>
                <DemoInstallButtonCmpLazy />
            </Suspense>
            <DemoInstallButton />
        </React.Fragment>;
};