import { TypedCustomerApi } from "@novel/shared/typedCustomerApi/TypedCustomerRoutes";
import { customerCsrfPropertyName } from "@novel/shared/utils/appConstants";
import { getCustomerCsrfToken } from "@novel/shared/utils/getCustomerCsrfToken";

// we are able to piggy-back off of customer auth for subdomains because it is cookie-based and we set
// the cookie with the "domain" option set to the root domain.
// https://stackoverflow.com/questions/18492576/share-cookies-between-subdomain-and-domain#answer-23086139
export const typedFrontendPassApi = new TypedCustomerApi("", [
    // using app prefixed host right now because otherwise cookie not shared
    (endpoint, requestConfig) => {
        const csrfToken = getCustomerCsrfToken();
        return {
            ...requestConfig,
            // we need to include the authentication cookie
            credentials: "same-origin",
            headers: {
                ...(!csrfToken ? {} : { [customerCsrfPropertyName]: csrfToken }),
                ...requestConfig.headers,
            },
        };
    },
]);
