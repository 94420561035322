import React, { useEffect, useState } from "react";
import { useNovelCustomerPassDispatch, useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { loadSession } from "@customer-pass/redux/actionCreators/session";
import { useAsyncEffect } from "@novel/shared/hooks/useAsyncEffect";
import { useAsyncCallback } from "@novel/shared/hooks/useAsyncCallback";
import { GeneralNovelCustomerPassDispatch } from "@customer-pass/redux/customerPassTypedRedux";
import noop from "lodash/noop";
import { getBlobUrl } from "@novel/shared/utils/downloadBlob";
import { UnsupportedBrowserScreen } from "@customer-pass/components/UnsupportedBrowser";
import { checkIsChrome, checkIsSafari } from "@novel/shared/utils/userAgentUtils";
import { DetectAdblock } from "@scthakuri/adblock-detector";
export function InstallApplePassCmp(): JSX.Element | null {
  const dispatch = useNovelCustomerPassDispatch();
  const orgId = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.org.id);
  const rewardsStatusId = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.rewardsStatus?.id);
  const passInstallLink = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.passInstallLink);
  const currentWalletPass = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.currentWalletPass);
  const authLink = useNovelCustomerPassSelector(state => state.auth.authLink);
  const [startDetect, setStartDetect] = useState(true);
  const [detected, setDetected] = useState(false);
  useEffect(() => {
    if (startDetect) {
      DetectAdblock(enable => {
        setDetected(enable);
        setStartDetect(false);
      });
    }
  }, [startDetect]);

  // pass install on iOS only works on Safari or Chrome
  const isUnsupportedBrowser = typeof window !== "undefined" ? !checkIsSafari() && !checkIsChrome() || detected : false;

  // cannot get deep link redirect to Safari to actually work
  // as of now redirecting to mobile safari is undocumented so makes sense
  // const authLink = useNovelCustomerPassSelector((state) => state.auth.authLink);
  // useEffect(() => {
  //     // if not the correct browser, deep link redirect the customer to Chrome
  //     if (isChromeBrowser && authLink) {
  //         // deep link redirect to Safari here
  //         // taken from: https://www.reddit.com/r/iOSProgramming/comments/tpuowz/figured_out_safari_url_scheme/
  //         try {
  //             window.open(`apple-mobilesafari-tab:${encodeURIComponent(authLink)}`, "_self");
  //         } catch (e: any) {
  //             // pre-ios 16
  //             window.open(`com-apple-mobilesafari-tab:${encodeURIComponent(authLink)}`, "_self");
  //         }

  //         // close this tab in the background once redirect completes
  //         const ASSUMED_REDIRECT_TIME = 5000;
  //         setTimeout(() => {
  //             window.close();
  //         }, ASSUMED_REDIRECT_TIME);
  //     }
  // }, [isChromeBrowser, authLink]);

  const installPassWithDispatch = useAsyncCallback(async () => {
    if (!isUnsupportedBrowser && passInstallLink && orgId && rewardsStatusId && !currentWalletPass?.shouldBeActive && !startDetect && !detected) {
      await installApplePass(dispatch, passInstallLink, orgId, rewardsStatusId);
    }
  }, [dispatch, isUnsupportedBrowser, currentWalletPass, passInstallLink, orgId, rewardsStatusId, startDetect, detected]);
  useAsyncEffect(installPassWithDispatch, noop, [installPassWithDispatch]);
  if (isUnsupportedBrowser) {
    return <UnsupportedBrowserScreen authLink={authLink} />;
  }
  return null;
}
const ASSUMED_INSTALL_TIME = 3000;
const RETRIES_FOR_INSTALL_CHECK = 3;
async function installApplePass(dispatch: GeneralNovelCustomerPassDispatch, passInstallLink: string, orgId: string, rewardsStatusId: string): Promise<void> {
  const passBlobUrl = await getBlobUrl({
    url: passInstallLink,
    fileName: `${orgId}-${rewardsStatusId}.pkpass`,
    errorMessage: "Failed to install pass, please try again in a few minutes."
  });
  await dispatch({
    type: "SET_PASS_DOWNLOAD_URL",
    payload: {
      passDownloadUrl: passBlobUrl
    }
  });

  // if (isAlreadyInstalled) {
  //     successToast({ content: "Pass already installed! Click the button below to view." });
  // }

  // reload the session to see if the pass was installed
  return await new Promise<void>(resolve => {
    setTimeout(resolveWithPass, ASSUMED_INSTALL_TIME);
    let retryCount = 0;
    async function resolveWithPass() {
      const result = await dispatch(loadSession());
      const customerRes = result.type === "LOADED_RESOLVED_CUSTOMER" && result.payload.resolvedCustomer || undefined;
      if (customerRes?.currentWalletPass) {
        resolve();
      } else if (retryCount < RETRIES_FOR_INSTALL_CHECK) {
        retryCount++;
        setTimeout(resolveWithPass, ASSUMED_INSTALL_TIME);
      }
    }
  });
}