import NovelLoader from "@novel/shared/components/NovelLoader";
import React from "react";
import styled from "styled-components";
export class SafeLoader extends React.Component<{
  className?: string;
}> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(props: {
    className?: string;
  }) {
    super(props);
  }
  state = {
    hasError: false
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }
  render(): JSX.Element | null {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null;
    }
    return <NovelLoader className={this.props.className} center speed="fast" />;
  }
}
const FixedLoaderContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
`;
export function FixedCenteredLoader(): JSX.Element {
  return <FixedLoaderContainer>
            <SafeLoader />
        </FixedLoaderContainer>;
}
const AbsoluteLoaderContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;
export function AbsoluteCenteredLoader({
  className
}: {
  readonly className?: string;
}): JSX.Element {
  return <AbsoluteLoaderContainer className={className}>
            <SafeLoader />
        </AbsoluteLoaderContainer>;
}