import parser, { IResult as UserAgentParsedResult } from "ua-parser-js";

const isMobileRegex =
    /Android|BlackBerry|CriOS|FxiOS|IEMobile|iPad|iPhone|iPod|Mobile|mobile|Opera Mini|webOS/i;
const isFirefoxRegex = /Firefox/i;
const isDuckDuckGoRegex = /Ddg|DuckDuckGo /i;

function resolveUserAgent(providedUserAgent?: string) {
    const resolvedUserAgent =
        providedUserAgent || (typeof window !== "undefined" && window.navigator.userAgent);
    if (!resolvedUserAgent) {
        throw new Error("No user agent provided");
    }

    // attempting to handle iOS 15 Pro Max user agent
    const cleanResolvedUserAgent = resolvedUserAgent.replace("like Mac OS X", "");
    return cleanResolvedUserAgent;
}

function parseUserAgent(providedUserAgent?: string): UserAgentParsedResult {
    const resolvedUserAgent = resolveUserAgent(providedUserAgent);
    return parser(resolvedUserAgent);
}

// console below = video game console
// embedded covers car browsers: https://github.com/faisalman/ua-parser-js/blob/a88660493568d6144a551424a8139d6c876635f6/src/ua-parser.js#L652
type DeviceType = "mobile" | "tablet" | "desktop" | "smarttv" | "wearable" | "embedded" | "console";
export function getDeviceType(providedUserAgent?: string): DeviceType | undefined {
    const resolvedUserAgent = resolveUserAgent(providedUserAgent);

    if (isMobileRegex.test(resolvedUserAgent)) {
        return "mobile";
    }

    const parsed = parseUserAgent(resolvedUserAgent);
    const osName = parsed.os.name?.toLowerCase().trim() || "";
    if (osName.includes("ios") || osName.includes("iphone") || osName.includes("android")) {
        return "mobile";
    }
    // attempting to handle iOS 15 Pro Max user agent
    if (
        (osName.includes("macos") || osName.includes("mac os")) &&
        !osName.includes("like mac os x")
    ) {
        return "desktop";
    }
    if (osName.includes("watch")) {
        return "wearable";
    }

    return parsed.device.type as DeviceType | undefined;
}

export function checkIsMobile(providedUserAgent?: string): boolean {
    return getDeviceType(providedUserAgent) === "mobile";
}

export function checkIsChrome(providedUserAgent?: string): boolean {
    const resolvedUserAgent = resolveUserAgent(providedUserAgent);
    return (
        (!isFirefoxRegex.test(resolvedUserAgent) &&
            !isDuckDuckGoRegex.test(resolvedUserAgent) &&
            !!parser(resolvedUserAgent).browser.name?.toLowerCase().includes("chrome")) ||
        !!parser(resolvedUserAgent).browser.name?.toLowerCase().includes("gsa")
    );
}

export function checkIsSafari(providedUserAgent?: string): boolean {
    const resolvedUserAgent = resolveUserAgent(providedUserAgent);
    return (
        !isFirefoxRegex.test(resolvedUserAgent) &&
        !isDuckDuckGoRegex.test(resolvedUserAgent) &&
        !!parser(resolvedUserAgent).browser.name?.toLowerCase().includes("safari")
    );
}
