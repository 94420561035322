import React from "react";
import QRCode from "react-qr-code";
import { FixedPxBox } from "@novel/shared/components/Box";
import { createGlobalStyle } from "styled-components";
import { StoreCreditCurrencyCode } from "@novel/shared/utils/formatMoney";
import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { LayoutDesktopCmp } from "@novel/shared/components/PassPreview/LayoutDesktop";
import { PassAnimation } from "@novel/shared/components/PassPreview/shared/PassAnimation";
import { CurrencyCode } from "@novel/shared/__generated__/graphql";
import { IOrgCustomerRelationshipWithRewardsStatusAndTier } from "@novel/shared/interfaces/OrgCustomerRelationship";
const DesktopPassPreviewStyle = createGlobalStyle`
    .Polaris-Icon {
        margin: initial !important;
    }

    .toggle-switch label {
        position: relative;
        display: block;
        width: 40px;
        height: 15px;
        background: rgb(157, 213, 255);
        border-radius: 15px;
    }

    .toggle-switch label:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: rgb(34, 151, 255);
        top: 50%;
        left: calc(40px - 20px);
        transform: translateY(-50%);
        border-radius: 50%;
        box-shadow: inset 0px 0px 1px 1px #ddd;
    }
    .heading-perk {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        color: #000000;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    .hello-subtext-mini {
        text-align: center;
        line-height: 1.5;
        margin-bottom: 5px;
    }
    .form-box {
        width: 400px;
        row-gap: 5.5rem;
    }
    .install-pass-animation {
        width: 270.6px;
        height: 301.57px;
    }
`;
export function DesktopPassPreview(props: Omit<IRewardsTierCreate, "tierIndex"> & {
  readonly userDisplayName?: string;
  readonly showPseudoQr?: boolean;
  readonly userId?: string;
  readonly orgName?: string;
  readonly currencyCode?: StoreCreditCurrencyCode;
  readonly authLink?: string | null;
  readonly referredBy?: IOrgCustomerRelationshipWithRewardsStatusAndTier;
  readonly guessedPhoneOperatingSystem?: "android" | "ios";
  readonly passOrgNameOverride?: string;
}): JSX.Element {
  const {
    appleLogoImageUrl,
    authLink,
    passOrgNameOverride,
    referredBy
  } = props;
  return <React.Fragment>
            <LayoutDesktopCmp passOrgNameOverride={passOrgNameOverride} {...props} authLink={authLink || undefined}>
                <FixedPxBox>
                    <FixedPxBox column rowGap={5.5} className="form-box" style={{
          padding: `0px`
        }}>
                        <FixedPxBox column rowGap={1} justifyContent="center" alignItems={"flex-start"}>
                            <FixedPxBox className="small-logo-container" width="100%" justifyContent="center" alignItems="center" mb={2.5}>
                                <PassAnimation referredBy={referredBy} className="install-pass-animation" rewardsTier={props} currencyCode={props.currencyCode || CurrencyCode.Usd} />
                            </FixedPxBox>
                            <FixedPxBox className="heading-perk">Get Your Wallet Pass</FixedPxBox>
                            <FixedPxBox className="hello-subtext-mini">
                                Scan the QR Code below with your phone to get your Wallet Pass
                            </FixedPxBox>
                            <div className="device-qr-code-container">
                                {authLink && <QRCode className="device-qr-code" value={`${props.userId ? authLink : `${authLink}?customer=${props.userId}`}`} size={168} style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%"
              }} viewBox={`0 0 125 125`} />}
                            </div>
                        </FixedPxBox>
                    </FixedPxBox>
                </FixedPxBox>
            </LayoutDesktopCmp>
            <DesktopPassPreviewStyle />
        </React.Fragment>;
}