import React from "react";
import QRCode from "react-qr-code";
import { FixedPxBox } from "@novel/shared/components/Box";
export function FallbackFlow({
  walletPassInstallFlow,
  authLink,
  userId
}: {
  readonly walletPassInstallFlow?: "wearable" | "unsupported-phone" | "unsupported-device";
  readonly authLink?: string | null;
  readonly userId?: string;
}): JSX.Element {
  return <React.Fragment>
            <FixedPxBox className="device-qr-code-container-wearable" column px={1} rowGap={1} justifyContent="center" alignItems="center">
                <FixedPxBox textAlign="center">
                    {walletPassInstallFlow === "wearable" ? "Scan with your phone to get your wallet pass" : "only iOS and Android phones are supported, if you own a supported phone, scan the QR code above to get your wallet pass"}
                </FixedPxBox>
                {authLink && <QRCode className="device-qr-code-wearable" value={`${userId ? authLink : `${authLink}?user=${userId}`}`} size={75} style={{
        height: "auto",
        maxWidth: "100%",
        width: "100%"
      }} viewBox={`0 0 125 125`} />}
            </FixedPxBox>
            <style>{`
                body {
                    background-color: #fff !important;
                    background-image: none !important;
                }
            `}</style>
        </React.Fragment>;
}