import { delay } from "@novel/shared/utils/delay";

export function poll<T>({
    pollFunction,
    emitResponse,
    exitCondition,
    waitBetweenAttempts = 250,
    maxAttempts = null,
}: {
    pollFunction: () => Promise<T>;
    emitResponse: (res: T, isExitting: boolean) => void | Promise<void>;
    exitCondition: (res: T) => boolean;
    waitBetweenAttempts?: number;
    maxAttempts?: number | null;
}): () => void {
    let currentRes: T | null = null;

    let exitConditionMet = false;
    let exitFunc: () => void = () => {
        exitConditionMet = true;
    };

    let currentAttemptCount = 0;
    (async () => {
        do {
            currentRes = await pollFunction();

            // "exitConditionMet" will have been set to true on first cycle only if exitFunc was called
            await emitResponse(currentRes, exitConditionMet);
            exitConditionMet = exitConditionMet || exitCondition(currentRes);

            await delay(waitBetweenAttempts);
            await new Promise<void>((resolve) => {
                const timeoutId = setTimeout(resolve, waitBetweenAttempts);

                // handles returning early if exitFunc is called
                exitFunc = () => {
                    exitConditionMet = true;
                    clearTimeout(timeoutId);
                    resolve();
                };
            });

            currentAttemptCount++;
            if (maxAttempts && currentAttemptCount >= maxAttempts) {
                break;
            }
        } while (!exitConditionMet);
        exitFunc();
    })();

    return () => {
        exitFunc();
    };
}
